import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';

const AshLabsVsHome: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="lg">
      <div className="flex w-full flex-col gap-6 p-4">
        <div>
          <h3 className="mb-3 text-xl font-bold">About your labs</h3>
          <p className="text-mSm">
            There are risks associated with starting the Triple Therapy medication protocol without
            completing the recommended lab work. Completing lab work is required before receiving
            the first medication refill.
          </p>
        </div>

        <Common.Button
          className="my-2 md:self-center"
          color="white-alt"
          fullWidthOnMobile
          onClick={onClose}
        >
          Close
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default AshLabsVsHome;
