import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';

import { SignUpProps } from 'store/signup/signup.types';

import { DEFAULT_SCALE_ADDON_CODE } from 'constants/defaults';
import {
  FLOW_TYPES,
  MIF_STEPS,
  SEMAGLUTIDE_PRICE_POINT,
  STATES_WHERE_ASH_KIT_IS_DISABLED,
  STATES_WHERE_ASYNC_FLOW_IS_ENABLE,
  TRIPLE_THERAPY_FLOW_STEPS,
  WM_FLOWS
} from 'constants/onboarding';
import { FlowTypes, PlanCodes, PlanTypes, SexAtBirth, SignUpSteps } from 'utils/enums';

import { MembershipPlan, PricePoint } from 'models/plans.types';

export const isForbiddenStepForNotSignedUp = (
  user: SignUpProps['user'],
  flow: FlowTypes,
  stepName: SignUpSteps
): boolean => {
  if (WM_FLOWS.includes(flow)) {
    return false;
  }
  if (
    [
      FlowTypes.ShortFlow,
      FlowTypes.ShortFlowWithOptionalPopup,
      FlowTypes.MixedFlow,
      FlowTypes.MixedFlowWithRequiredPopup
    ].includes(flow)
  ) {
    return (
      !user.accessToken &&
      [
        SignUpSteps.CreateNewUser,
        SignUpSteps.TwoOptionsFinish,
        SignUpSteps.Pharmacy,
        SignUpSteps.Confirmation
      ].includes(stepName)
    );
  } else {
    return (
      !user.accessToken &&
      [
        SignUpSteps.Summary,
        SignUpSteps.Payment,
        SignUpSteps.PreConfirmation,
        ...MIF_STEPS,
        SignUpSteps.Pharmacy,
        SignUpSteps.MifSummary,
        SignUpSteps.PreSymptomChecker,
        SignUpSteps.SymptomsSelector,
        SignUpSteps.SCRegions,
        SignUpSteps.SCResults,
        SignUpSteps.Interview,
        SignUpSteps.SwitchToApp,
        SignUpSteps.TwoOptionsFinish,
        SignUpSteps.Confirmation
      ].includes(stepName)
    );
  }
};

export const isForbiddenStepForSignedUp = (
  userData: { completedWMOnboarding?: boolean; hasSubscription?: boolean; status?: string },
  flow: FlowTypes,
  stepName: SignUpSteps
) => {
  if (
    WM_FLOWS.includes(flow) &&
    userData.completedWMOnboarding &&
    stepName !== SignUpSteps.WMCompleteOnboarding
  ) {
    return false;
  }
  if (
    [
      FlowTypes.ShortFlow,
      FlowTypes.ShortFlowWithOptionalPopup,
      FlowTypes.MixedFlow,
      FlowTypes.MixedFlowWithRequiredPopup
    ].includes(flow)
  ) {
    return (
      userData.hasSubscription &&
      [SignUpSteps.ChooseProvider, SignUpSteps.PlanSelect].includes(stepName)
    );
  } else {
    return (
      userData.status === 'active' &&
      [
        SignUpSteps.AppointmentCategory,
        SignUpSteps.AppointmentDetails,
        SignUpSteps.ChooseProvider,
        SignUpSteps.CreateNewUser,
        SignUpSteps.Summary
      ].includes(stepName)
    );
  }
};

export const isShowHeaderLogos = ({ flow, step }: { flow: FlowTypes; step: SignUpSteps }) => {
  if (flow !== FlowTypes.BlueLineFlow) {
    return true;
  }
  return step !== SignUpSteps.BlueLineIntro;
};

export const isShowBackButtonFunc = (stepName: SignUpSteps, flow: FlowTypes, stepIndex = 0) => {
  if (flow === FlowTypes.BlueLineFlow) {
    return true;
  }
  if (flow === FlowTypes.QueueFlow) {
    return ![
      MIF_STEPS[0],
      SignUpSteps.PreConfirmation,
      SignUpSteps.PreSymptomChecker,
      SignUpSteps.SCResults,
      SignUpSteps.SwitchToApp
    ].includes(stepName);
  }
  return (
    stepIndex > 0 &&
    ![
      MIF_STEPS[0],
      SignUpSteps.PreConfirmation,
      SignUpSteps.PreSymptomChecker,
      SignUpSteps.SymptomsSelector,
      SignUpSteps.SCRegions,
      SignUpSteps.Interview,
      SignUpSteps.Confirmation,
      SignUpSteps.TwoOptionsFinish,
      SignUpSteps.SCResults
    ].includes(stepName)
  );
};

export const handleUrl = (
  p: PlanTypes,
  f: FlowTypes,
  sn: SignUpSteps,
  shouldResetStoreAndFlow: boolean
) => {
  let patchedPlan = p;
  let patchedFlow = f;
  let patchedStepName = sn;
  let isValidParams = true;

  if (!Object.values(FlowTypes).includes(patchedFlow)) {
    patchedFlow = FlowTypes.WeightManagementFlow;
    patchedPlan = PlanTypes.WeightManagement;
    isValidParams = false;
  }
  // if (patchedFlow === FlowTypes.HRTClubFlow && patchedPlan !== PlanTypes.TotalCare) {
  //   patchedPlan = PlanTypes.TotalCare;
  //   isValidParams = false;
  // }
  if (!Object.values(PlanTypes).includes(patchedPlan)) {
    patchedFlow = FlowTypes.WeightManagementFlow;
    patchedPlan = PlanTypes.WeightManagement;
    isValidParams = false;
  }
  if (!Object.values(SignUpSteps).includes(patchedStepName) || shouldResetStoreAndFlow) {
    patchedStepName = FLOW_TYPES[patchedFlow][0];
    isValidParams = false;
  }
  return { isValidParams, patchedFlow, patchedPlan, patchedStepName };
};

export const getContainerWidth = (step: SignUpSteps, flow?: FlowTypes) => {
  if (!!flow && WM_FLOWS.includes(flow)) {
    switch (true) {
      case step === SignUpSteps.MarketingRatedNumberOne:
      case step === SignUpSteps.PlanSelect:
      case step === SignUpSteps.WMScheduleAppointment:
        return 'max-w-[704px]';
      case step === SignUpSteps.WMCheckout:
      case step === SignUpSteps.WMCompleteOnboarding:
      case step === SignUpSteps.AppointmentConfirmation:
        return 'max-w-[800px]';
      case step === SignUpSteps.Insurance:
      case step === SignUpSteps.PreInsurance:
      case step === SignUpSteps.VerifyIdentity:
        return 'max-w-[640px]';
      case step === SignUpSteps.MarketingMoneyBackGuarantee:
      case step === SignUpSteps.MarketingRegularMeetings:
        return 'max-w-[1020px]';
      default:
        return 'max-w-[500px]';
    }
  }
  return [
    SignUpSteps.Payment,
    SignUpSteps.PlanSelect,
    SignUpSteps.Pharmacy,
    SignUpSteps.AppointmentCategory
  ].includes(step)
    ? 'max-w-[912px]'
    : [SignUpSteps.PreSymptomChecker, SignUpSteps.PreConfirmation, ...MIF_STEPS].includes(step)
      ? 'max-w-[500px]'
      : [SignUpSteps.SymptomsSelector].includes(step)
        ? 'max-w-[1024px]'
        : [SignUpSteps.PlanSelect].includes(step)
          ? 'max-w-[1120px]'
          : [SignUpSteps.Summary].includes(step)
            ? 'max-w-[664px]'
            : 'max-w-[640px]';
};

export const checkStepsList = (
  userData: {
    BMI: number;
    pricePointID: string;
    sexAtBirth: SexAtBirth | '';
    state: string;
  },
  steps: SignUpSteps[],
  insuranceStatus: string,
  flow: FlowTypes
) => {
  const { sexAtBirth, state, BMI, pricePointID } = userData;
  let newStepsList = [...steps];
  let shouldUpdateSteps = false;

  const insuranceFlowSteps = [
    SignUpSteps.WithOrWithoutInsurance,
    SignUpSteps.Insurance,
    SignUpSteps.CheckPrescriptionCoverage
  ];

  const insuranceRelatedSteps = [
    SignUpSteps.ShouldUseInsurance,
    SignUpSteps.PreInsurance,
    SignUpSteps.MarketingLowerPrice,
    SignUpSteps.Insurance
  ];

  if (!steps.length) {
    return { newStepsList, shouldUpdateSteps };
  }

  if (flow === FlowTypes.TripleTherapy) {
    newStepsList = TRIPLE_THERAPY_FLOW_STEPS;
    shouldUpdateSteps = !steps.includes(SignUpSteps.WMTTResults);
    if (STATES_WHERE_ASH_KIT_IS_DISABLED.includes(state) && steps.includes(SignUpSteps.WMLabWork)) {
      newStepsList = TRIPLE_THERAPY_FLOW_STEPS.filter((s) => s !== SignUpSteps.WMLabWork);
      shouldUpdateSteps = true;
    } else if (
      !STATES_WHERE_ASH_KIT_IS_DISABLED.includes(state) &&
      !steps.includes(SignUpSteps.WMLabWork)
    ) {
      const indexOfTTresultsStep = steps.indexOf(SignUpSteps.WMTTResults);
      if (indexOfTTresultsStep > -1) {
        const arr = [...steps];
        arr.splice(indexOfTTresultsStep + 1, 0, SignUpSteps.WMLabWork);
        newStepsList = arr;
        shouldUpdateSteps = true;
      }
    }
  }

  // conditions to toggle select appt type step
  const conditionsToShowSelectApptType =
    STATES_WHERE_ASYNC_FLOW_IS_ENABLE.includes(state) &&
    pricePointID === SEMAGLUTIDE_PRICE_POINT &&
    BMI >= 30;
  if (
    flow === FlowTypes.WeightManagementFlowAsync &&
    conditionsToShowSelectApptType &&
    !steps.includes(SignUpSteps.PickVideoOrMessageAppointment)
  ) {
    const indexOfSchedulingApptStep = steps.indexOf(SignUpSteps.WMScheduleAppointment);
    if (indexOfSchedulingApptStep > -1) {
      const arr = [...steps];
      arr.splice(indexOfSchedulingApptStep, 0, SignUpSteps.PickVideoOrMessageAppointment);
      newStepsList = arr;
      shouldUpdateSteps = true;
    }
  } else if (
    !conditionsToShowSelectApptType &&
    steps.includes(SignUpSteps.PickVideoOrMessageAppointment)
  ) {
    newStepsList = steps.filter((s) => s !== SignUpSteps.PickVideoOrMessageAppointment);
    shouldUpdateSteps = true;
  }

  if (
    WM_FLOWS.includes(flow) &&
    !!state &&
    state !== 'California' &&
    steps.includes(SignUpSteps.NoticeForCalifornia)
  ) {
    newStepsList = steps.filter((s) => s !== SignUpSteps.NoticeForCalifornia);
    shouldUpdateSteps = true;
  }

  if (sexAtBirth !== SexAtBirth.Female && steps.includes(SignUpSteps.WomensHealth)) {
    newStepsList = steps.filter((s) => s !== SignUpSteps.WomensHealth);
    shouldUpdateSteps = true;
  }
  if (sexAtBirth === SexAtBirth.Female && !steps.includes(SignUpSteps.WomensHealth)) {
    const indexAfterWomen = steps.indexOf(SignUpSteps.SocialHistory);
    if (indexAfterWomen > -1) {
      const arr = [...steps];
      arr.splice(indexAfterWomen, 0, SignUpSteps.WomensHealth);
      newStepsList = arr;
      shouldUpdateSteps = true;
    }
  }
  if (flow === FlowTypes.WeightManagementFlowInsuranceAlt && !!state) {
    const isInsuranceAvailableInUsersState =
      sessionStorage.getItem('isInsuranceAvailableInUsersState') === 'true';

    const insAltSteps: SignUpSteps[] = [
      SignUpSteps.InsuranceAppointmentsAndMedications,
      SignUpSteps.InsuranceForAppointments,
      SignUpSteps.Insurance,
      SignUpSteps.InsuranceMedicationsToBeDiscussed
    ];

    if (!isInsuranceAvailableInUsersState && steps.some((s) => insAltSteps.includes(s))) {
      newStepsList = steps.filter((s) => !insAltSteps.includes(s));
      shouldUpdateSteps = true;
    } else if (isInsuranceAvailableInUsersState && !steps.some((s) => insAltSteps.includes(s))) {
      const indexOfInsuranceTypesStep = steps.indexOf(SignUpSteps.InsuranceForMedications);
      if (indexOfInsuranceTypesStep > -1) {
        const arr = [...newStepsList];
        arr.splice(indexOfInsuranceTypesStep, 0, ...insAltSteps);
        newStepsList = arr;
        shouldUpdateSteps = true;
      }
    }
  }
  if (flow === FlowTypes.WeightManagementFlowInsurance) {
    const isInsuranceAvailableInUsersState =
      sessionStorage.getItem('isInsuranceAvailableInUsersState') === 'true';
    const conditionsToIncludeInsuranceSteps =
      insuranceStatus === 'employer_commercial' && isInsuranceAvailableInUsersState;

    if (!conditionsToIncludeInsuranceSteps && steps.some((s) => insuranceFlowSteps.includes(s))) {
      newStepsList = steps.filter((s) => !insuranceFlowSteps.includes(s));
      shouldUpdateSteps = true;
    } else if (
      conditionsToIncludeInsuranceSteps &&
      !steps.some((s) => insuranceFlowSteps.includes(s))
    ) {
      const indexOfSelectPlanStep = steps.indexOf(SignUpSteps.PlanSelect);
      if (indexOfSelectPlanStep > -1) {
        const arr = [...newStepsList];
        arr.splice(indexOfSelectPlanStep - 1, 0, ...insuranceFlowSteps);
        newStepsList = arr;
        shouldUpdateSteps = true;
      }
    }
  }
  if (
    [
      FlowTypes.WeightManagementFlowExtended,
      FlowTypes.WeightManagementFlowOptavia,
      FlowTypes.WeightManagementFlowAsync,
      FlowTypes.WeightManagementFlowInsuranceMedications
    ].includes(flow) &&
    insuranceStatus
  ) {
    if (insuranceStatus === 'uninsured' && steps.some((s) => insuranceRelatedSteps.includes(s))) {
      newStepsList = steps.filter((s) => !insuranceRelatedSteps.includes(s));
      shouldUpdateSteps = true;
    } else if (
      insuranceStatus !== 'uninsured' &&
      [
        FlowTypes.WeightManagementFlowAsync,
        FlowTypes.WeightManagementFlowInsurance,
        FlowTypes.WeightManagementFlowInsuranceMedications
      ].includes(flow) &&
      !steps.includes(SignUpSteps.ShouldUseInsurance)
    ) {
      const indexOfSelectPlanStep = steps.indexOf(SignUpSteps.PlanSelect);
      if (indexOfSelectPlanStep > -1) {
        const arr = [...newStepsList];
        arr.splice(indexOfSelectPlanStep, 0, SignUpSteps.ShouldUseInsurance);
        newStepsList = arr;
        shouldUpdateSteps = true;
      }
    }
  }
  return { newStepsList, shouldUpdateSteps };
};

export const findMifStepIndexByName = (
  stepName: SignUpSteps,
  sexAtBirth: SexAtBirth | '',
  flow?: FlowTypes
) => {
  if (!flow) {
    return -1;
  }
  const steps = FLOW_TYPES[flow].filter((s) =>
    sexAtBirth === SexAtBirth.Female ? true : s !== SignUpSteps.WomensHealth
  );
  return steps.indexOf(stepName);
};

export const getProgressInfo = (
  step: number,
  stepsList: SignUpSteps[]
): { currStep: number; stepsAmount: number } => {
  let currStep = step;
  let stepsAmount = stepsList.length;
  const indexOfStepWithPreConfirm = stepsList.indexOf(SignUpSteps.PreConfirmation) + 1;
  if (stepsList.includes(SignUpSteps.PreConfirmation)) {
    const stepsBeforePre = stepsList.slice(0, indexOfStepWithPreConfirm);
    const stepsAfterPre = stepsList.slice(indexOfStepWithPreConfirm);
    if (step <= indexOfStepWithPreConfirm) {
      currStep = Math.min(stepsBeforePre.length - 1, step);
      stepsAmount = stepsBeforePre.length;
    } else {
      stepsAmount = stepsAfterPre.length;
      currStep = stepsAfterPre.indexOf(stepsList[step - 1]) + 1;
    }
  }

  return { currStep, stepsAmount };
};

export const getPlanByUrlParam = (
  plan: PlanTypes,
  plansList: MembershipPlan[]
): MembershipPlan | null => {
  let selectedPlan = null;
  switch (plan) {
    case PlanTypes.UnlimitedPlan:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.UnlimitedMembership);
      break;
    case PlanTypes.LifeMDMembership:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.LifeMDMembership);
      break;
    case PlanTypes.FlexCare:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.FlexCare);
      break;
    case PlanTypes.TotalCare:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.TotalCareMembership);
      break;
    case PlanTypes.ConciergeCare:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.ConciergeMembership);
      break;
    case PlanTypes.UrgentCare:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.UrgentCare);
      break;
    case PlanTypes.WeightManagement:
      selectedPlan = plansList.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
      break;
    default:
      break;
  }
  return selectedPlan || null;
};

export const getScalesPriceByPPLength = (pp?: PricePoint) => {
  if (!pp) {
    return null;
  }
  const price = pp.addOns?.find((a) => a.code === DEFAULT_SCALE_ADDON_CODE)?.unitPrice ?? '';
  if (!!price) {
    return price;
  }
  return null;
};

export const parseSignupSession = () => {
  const cookie = Cookies.get('onboarding-session');
  if (!cookie) {
    const date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 24 * 1000); // 24 hours
    Cookies.set('onboarding-session', nanoid(), {
      expires: date
    });
  }

  return !!cookie;
};

export const setPartnerData = (flow: FlowTypes) => {
  switch (flow) {
    case FlowTypes.BlueLineFlow:
      return {
        companyPartners: {
          connectedPartners: [{ partnerName: 'Blue Line' }],
          onboardingPartner: {
            extraPartnerData: {},
            partnerName: 'Blue Line',
            token: '',
            tokenIsValid: false
          }
        }
      };
    case FlowTypes.WeightManagementFlowOptavia:
      return {
        companyPartners: {
          connectedPartners: [{ partnerName: 'Optavia' }],
          onboardingPartner: {
            extraPartnerData: {},
            partnerName: 'Optavia',
            token: '',
            tokenIsValid: false
          }
        }
      };
    default:
      return null;
  }
};
